<template>
  <div>
    <v-sheet class="pa-8">
      <v-row
        class="mb-6"
        no-gutters>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4">
          <v-select
            v-model="query.warehouse"
            :class="$vuetify.breakpoint.xs ? '' : 'mr-3'"
            expanded
            :items="warehouses"
            label="Warehouse"
            dense
            item-text="name"
            item-value="id"
            outlined
            @change="query.search = search"></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="5"
          lg="5"
          xl="5"
          class="d-flex align-right">
          <v-text-field
            v-model.trim="search"
            placeholder="SN / Asset ID / POS ID"
            class="mr-3"
            outlined
            dense
            color="secondary"></v-text-field>
          <v-btn
            :loading="loading"
            @click="query.search = search">
            <v-icon left>
              mdi-magnify
            </v-icon>
            Search
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="3"
          xl="3"
          class="d-flex align-right">
          <v-btn
            color="secondary"
            :to="{ name: 'CreatePos' }"
            class="ml-auto">
            <v-icon left>
              mdi-plus
            </v-icon>
            NEW POS
          </v-btn>
        </v-col>
      </v-row>
      <p class="text-h6">
        POS LIST
      </p>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait">
        <template v-slot:item.serialNumber="{ item }">
          {{ item.serialNumber || '-' }}
        </template>
        <template v-slot:item.assetId="{ item }">
          {{ item.assetId || '-' }}
        </template>
        <template v-slot:item.posId="{ item }">
          {{ item.posId || '-' }}
        </template>
        <template v-slot:item.branchModel="{ item }">
          {{ item.brandModel || '-' }}
        </template>
        <template v-slot:item.warehouse="{ item }">
          {{ item.warehouse.name || '-' }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status ? item.status.toUpperCase() : '-' }}
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'EditPos', params: { id: item.id } }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </router-link>
          <router-link :to="{ name: 'PreviewPosData', params: { id: item.id } }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-eye
            </v-icon>
          </router-link>
        </template>
      </v-data-table>
      <div class="text-center mt-4">
        <v-pagination
          v-model="query.page"
          color="secondary"
          :length="totalPages"></v-pagination>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import PosManagementProvider from '@/resources/PosManagementProvider'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data () {
    return {
      posManagementProvider: new PosManagementProvider(),
      items: [],
      totalPages: 0,
      totalItems: 0,
      loading: false,
      search: '',
      defaultPaginate: {
        sortBy: 'createdAt',
        sortOrder: 'desc',
        warehouse: '',
        page: 1,
        limit: 10,
        search: '',
        status: ''
      },
      query: {
        sortBy: 'createdAt',
        sortOrder: 'desc',
        warehouse: '',
        page: 1,
        limit: 10,
        search: '',
        status: ''
      },
      headers: [
          {
            text: 'Serial Number',
            align: 'start',
            value: 'serialNumber',
            sortable: false
          },
          {
            text: 'Asset ID',
            align: 'start',
            value: 'assetId',
            sortable: false
          },
          {
            text: 'POS ID',
            align: 'start',
            value: 'posId',
            sortable: false
          },
          {
            text: 'Warehouse',
            align: 'start',
            value: 'warehouse.name',
            sortable: false
          },
          {
            text: 'Branch / Model',
            align: 'start',
            value: 'branchModel',
            sortable: false
          },
          {
            text: 'Status',
            align: 'center',
            value: 'status',
            sortable: false
          },
          { text: 'Actions', align: 'center', value: 'actions', sortable: false }
        ]
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return [
        {
          id: '',
          name: 'All',
          code: 'all'
        },
        ...this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
      ]
    }
  },
  watch: {
    '$route.query': {
      handler () {
        this.fetchPaginate()
      },
      deep: true
    },
    'query': {
      handler () {
        this.updateQuery()
      },
      deep: true
    }
  },
  mounted () {
    if (
      this.$route.query.sortBy
      || this.$route.query.sortOrder
      || this.$route.query.page
      || this.$route.query.limit
      || this.$route.query.search
      || this.$route.query.status
      || this.$route.query.warehouse
    ) {
      this.query = {
        sortBy: this.$route.query.sortBy || this.defaultPaginate.sortBy,
        sortOrder: this.$route.query.sortOrder || this.defaultPaginate.sortOrder,
        page: +this.$route.query.page || this.defaultPaginate.page,
        limit: +this.$route.query.limit || this.defaultPaginate.limit,
        search: this.$route.query.search || this.defaultPaginate.search,
        status: this.$route.query.status || this.defaultPaginate.status,
        warehouse: +this.$route.query.warehouse || this.defaultPaginate.warehouse
      }
      this.search = this.$route.query.search || this.defaultPaginate.search
    } else {
      this.fetchPaginate()
    }
  },
  methods: {
    async fetchPaginate (isClear = false) {
      if (isClear) {
        this.query = this.defaultPaginate
      }
      try {
        this.loading = true
        const { data } = await this.posManagementProvider.paginate(this.query)
        if (data) {
          this.items = data.results
          this.totalPages = data.pages
          this.totalItems = data.total
        }
      } catch (error) {
        console.error('fetchPaginate', error)
      }
      this.loading = false
    },
    updateQuery () {
      this.$router.push({
        query: this.query
      }).catch(() => {
        this.fetchPaginate()
      })
    }
  }
}
</script>
